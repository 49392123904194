<template>
    <div :class="{'mb-3':!slim}">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" :required="required" :id="id" v-model="value" :class="{filled: !!value}">
            <label class="form-check-label" :class="{required}" :for="id">
                {{  label }}
            </label>

        </div>
        <p class="form-control-error text-danger" :key="msg" v-for="msg in error">błąd: {{ msg }}</p>
    </div>
</template>

<script>
import control from "@/mixins/control";

export default {
    name: "v-control-input",
    mixins: [control],
    props: {
        type: {default: 'checkbox'}
    },
    mounted() {

        console.log(this);
    }
}
</script>
