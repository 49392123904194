<template>
    <section>
        <v-model.apply v-slot="{model,checkedProducts, loading}">
            <div class="d-flex justify-content-between">
                <div class="hgroup">
                    <h1>Złóż nowy wniosek</h1>
                    <h2>To Twoje centrum dowodzenia - nowy wniosek klienta</h2>
                </div>
            </div>
            <v-wniosker-products :products="model.products"/>

            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <v-collection :items="model.agreements" >
                        <template v-slot:default="{ item }">

                            <v-control-checkbox type="checkbox" :required="!!item.required" :label="item.label" :slim="true" v-model="item.checked" />

                        </template>
                    </v-collection>
                </div>
                <div v-if="model.products.length">
                    <v-button submit :loading="loading" color="gold" size="lg" icon="file-paper" :label="checkedProducts.length ? 'Złóż wniosek na wybrane produkty' : 'Złóż wniosek na wszystkie produkty'"/>
                </div>
            </div>

        </v-model.apply>
    </section>
</template>

<script>
export default {
    name: "route-agent.apply",
    route: {
        path: 'wnioskowanie/:client?',
        title: "Złóż nowy wniosek"
    }
}
</script>