import { createApp } from 'vue'
import App from './App.vue'

import Router from './plugins/router'
import Loader from './plugins/loader';
import Auth from './plugins/auth';
import Storage from './plugins/storage';
import Wniosker from './plugins/wniosker';
import Http from './plugins/http';
import './plugins/helper';

import './styles/index.scss';
import 'v-calendar/dist/style.css';

(async () => {
    const env = await fetch('/env.json').then((r) => r.json()) ?? {};
    createApp(App)
        .use(Loader)
        .use(Router)
        .use(Storage)
        .use(Http, env.http)
        .use(Auth, env.auth)
        .use(Wniosker, env.wniosker)
        .mount('#app')

})();