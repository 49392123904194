<template>
    <section>
        <v-model.clients v-slot="{model,query, fetch, clearQuery, pagination}">

            <div class="d-flex justify-content-between">
                <div class="hgroup">
                    <h1>Baza klientów</h1>
                    <h2>To Twoje centrum klientów</h2>
                </div>
                <div>
                    <v-button color="secondary" :route="{name:'agent.import'}" label="Import klientów" size="xs" :outline="true" icon="clockwise"/>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
<!--                        <div class="d-flex justify-content-between">-->
<!--                            <div class="flex-grow-6">-->
                                <span class="card-title mb--4">Filtruj wyniki</span>
<!--                            </div>-->
<!--                            <div class="flex-grow-1 d-grid gap-2">-->
<!--                               -->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="d-flex mt-2   align-items-end">
                            <div class="flex-grow-1  d-grid gap-2">
                                <v-button :outline="true" color="secondary" size="xs" :label="advanced ? 'Ukryj zaawansowane' : 'Szukanie zaawansowane'" icon="arrow-down-s" @click="advanced = !advanced" />
                            </div>
                            <div class="flex-grow-5 d-flex px-4">
                                <div class="row">
                                    <div class="col-md">
                                        <v-control-input :slim="true" v-model="query['personId'].equal" label="Pesel"
                                                         placeholder="Podaj nr pesel"/>
                                    </div>
                                    <div class="col-md">
                                        <v-control-input :slim="true" v-model="query['phone'].equal" label="Telefon"
                                                         placeholder="Podaj nr tel."/>
                                    </div>
                                    <div class="col-md">
                                        <v-control-input :slim="true" v-model="query['email'].equal" label="Email"
                                                         placeholder="Podaj email"/>
                                    </div>
                                    <div class="col-md">
                                        <v-control-input :slim="true" v-model="query['firstName'].equal" label="Imię"
                                                         placeholder="Podaj imię"/>
                                    </div>
                                    <div class="col-md">
                                        <v-control-input :slim="true" v-model="query['lastName'].equal" label="Nazwisko"
                                                         placeholder="Podaj nazwisko"/>
                                    </div>

                                </div>
                            </div>
                            <div class="flex-grow-1  d-grid gap-2">
                                <v-button @click="clearQuery" :outline="true" color="secondary"  label="Wyczyść filtry" size="xs" icon="file-add-line-1"/>
                                <v-button @click="fetch" color="primary" size="sm" label="Szukaj" icon="search" />
                            </div>
                        </div>
                        <div class="mt-3 pt-3 border-top d-flex" v-if="advanced">
                            <div class="d-flex align-items-center mx-1">
                                <template v-if="query.from_at !== 'custom'">
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'today']" class="btn-xs mx-1"
                                                      label="Dziś"/>
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'yesterday']" class="btn-xs mx-1"
                                                      label="Wczoraj"/>
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'3days']" class="btn-xs mx-1"
                                                      label="3 dni"/>
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'7days']" class="btn-xs mx-1"
                                                      label="7 dni"/>
                                </template>

                                <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                  :options="[null,'custom']" class="btn-xs mx-1"
                                                  label="Własny"/>

                                <v-control-date-range  v-if="query.from_at === 'custom'" v-model="query.created_at"></v-control-date-range>
                            </div>

                            <div class="d-flex align-items-center mx-1">
                                <v-control-toggle v-if="$auth.permissions.mobileEnabled" v-model="query.hasMobileApp" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="smartphone"
                                                  title="Klient ukończył wypełanie formularza"/>
                                <v-control-toggle v-model="query.leadRedirected" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="direction"
                                                  title="SMS ze zgodami został wysłany"/>
                                <v-control-toggle v-model="query.leadAccepted" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="thumb-up"
                                                  title="Klient zaznaczył zgody"/>

                            </div>

                        </div>
                </div>

            </div>

            <div class="card mt-3">
                <div class="card-body ">
                    <span class="card-title">Wyniki wyszukiwania ({{ pagination?.total }})</span>

                    <v-table :items="model.clients" v-slot="{item}" class="clients">
                        <v-column label="Pesel">
                            <span>{{ item.pesel }}</span>
                        </v-column>
                        <v-column label="Nazwisko" style="width: 180px">
                            <span class="d-block">{{ item.lastName }}</span>
                            <small class="d-block">{{ item.firstName }}</small>
                        </v-column>
                        <v-column label="Email" >
                            <span>{{ item.email }}</span>
                        </v-column>
                        <v-column label="Telefon" >
                            <span>{{ item.phone }}</span>
                        </v-column>
                        <v-column label="Aplikacja" v-if="$auth.permissions.mobileEnabled">
                            <div class="d-flex align-items-center" :class="{'text-green': item.hasMobileApp, 'text-red': !item.hasMobileApp}">
                                <v-icon icon="smartphone"></v-icon>
                                <div class="fw-700 text-small text-uppercase mt-1 mx-1">{{ item.hasMobileApp ? 'Aktywna' : 'Brak' }}</div>
                            </div>

                        </v-column>
                        <v-column label="Wnioski" class="text-end">
                            <span class="number">{{ item.leads_processed_count || '0' }}</span>
                            <span class="unit">szt.</span>
                        </v-column>
                        <v-column label="Wypłaty" class="text-end">
                            <span class="number">{{ item.leads_paid_count ?? '0' }}</span>
                            <span class="unit">szt.</span>
                        </v-column>
                        <v-column label="Data utworzenia">
                            <v-date class="d-block" :date="item.created_at" format="YYYY/MM/DD" />
                            <v-date class="d-block" :date="item.created_at" format="HH:mm" />
                        </v-column>
                        <v-column label="Agent/Ekspert"  v-if="$auth.permissions.viewAgents">
                            <v-agent :agent="item.agent"/>
                        </v-column>

                        <v-column class="text-end" style="width: 180px">
                            <template v-if="item.uid">
                                <v-button :route="{name:'agent.apply', params: {client: item.uid}}" color="gold" size="xs" icon="file-add-line-1" label="Nowy wniosek" class="mb-1"/>
                                <v-button :route="{name:'agent.clients.preview', params: {client: item.uid}}" color="secondary" size="xs" :outline="true" icon="chat-history-line" label="Historia klienta"/>
                            </template>
                       </v-column>
                    </v-table>

                    <v-pagination :pagination="pagination" />
                </div>
            </div>
        </v-model.clients>
    </section>
</template>

<script>
import VButton from "@/components/Button";
import VColumn from "@/components/Column";
import agents from "@/models/options/agents";
export default {
    name: "route-agents.leads",
    components: {VColumn, VButton},
    mixins: [agents],
    route: {
        path: 'klienci',
        title: 'Klienci'
    },
    data() {
        return {
            advanced: false
        }
    }
}
</script>
