<template>
    <div class="col-md-3">
        <div class="product mb-3 " :class="{active: offer.value}">
            <div class="p-3 flex-grow-1">
                <div class="py-1">
                    <div  style="height: 60px">
                        <img :alt=" offer.label" :src="offer.thumbnail" class="logo-image" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md text-center">
                        <span>Kwota: </span>
                        <span class="number">{{ parseFloat(offer.amount).toPrettyFormat() }} </span>
                        <span class="unit">{{ offer.amountlabel }}</span>
                    </div>
                    <div class="col-md text-center">
                        <span>Okres: </span>
                        <span class="number">{{ offer.period }} </span>
                        <span class="unit">msc</span>
                    </div>
                </div>
            </div>
            <div>
                <v-control-toggle :label="['Zaznacz ofertę', 'Zaznaczono']" :color="[null,'primary']" v-model="offer.checked" size="lg" />
            </div>

        </div>

    </div>
</template>
<script>
export default {
    name: "v-wniosker-offer",
    props: {
        offer: {required: true}
    }
}
</script>