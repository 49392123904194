<template>
    <div class="card">
        <div class="card-body">
            <span class="card-title">{{ group.label }}</span>
            <div class="row mt-3">
                <v-wniosker-products :products="group.elements">
                    <div class="col-md-3"><slot/></div>
                </v-wniosker-products>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "v-wniosker-group",
    props: {
        group: { required: true}
    }
}
</script>
