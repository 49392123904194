import {h} from "vue";
import agents from "@/models/options/agents";

export default {
    data() {
       return {
           form: {
               agent_id: null
           },
           options: []
       }
    },
    mixins: [agents],
    created() {
        this.$globalQueries.set('agent_id', this.$route.query.agent_id ??  null);
        this.form.agent_id = this.$route.query.agent_id ?? '';
        this.agentsOptions().then((options) => this.options = options);
    },
    computed: {
        selectedAgent() {
            return Array.prototype.find.call(this.options, (item) =>  item.value && item.value === this.$globalQueries.params.agent_id );
            // Array.prototype.find.call(, (item) => console.log(item) );
        }
    },
    methods: {
        submit() {
           this.$globalQueries.set('agent_id', this.form.agent_id);
        },
        clear() {
            this.$globalQueries.set('agent_id', null);
            this.form.agent_id = '';
        }
    },
    provide() {
        return {
            global: this
        };
    },
    render() {

        const children = this.$slots.default(this);

        return this.$options.form ? h('form', {ref: 'form', onSubmit: this.submit}, children) : children;
    }
}