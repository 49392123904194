<template>
    <div>
        <label v-if="label" class="form-label">{{ label }}</label>


        <date-picker v-model="value" :mode="type" is-range is24hr>
            <template v-slot="{ inputValue, inputEvents }">
                <div class="input-group d-flex " v-on="inputEvents.start">
                    <div class="flex-grow-1">
                        <input type="text"  class="form-control" autocomplete="off"  placeholder="Od:" :value="inputValue.start">
                    </div>
                    <div  class="flex-grow-1">
                        <input type="text" class="form-control"  autocomplete="off" placeholder="Do:" :value="inputValue.end">
                    </div>
                </div>

            </template>
        </date-picker>


        <p v-for="msg in error" :key="msg" class="form-control-error text-danger">błąd: {{ msg }}</p>
    </div>
</template>

<script>
import control from "@/mixins/control";

import {DatePicker} from 'v-calendar';

export default {
    name: "v-control-date-range",
    mixins: [control],
    props: {
        type: {type: String, default: 'text'},
        mode: {type: String, default: 'date'}
    },
    components: {
        DatePicker
    }
}
</script>
