<template>
    <div class="col-md-3">
        <div class="product mb-3">
            <div class="p-3 flex-grow-1">
                <div class="py-1">
                    <div style="height: 60px">
                        <img :alt=" product.label" :src="product.thumbnail" class="logo-image"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                            <span>Kwota: </span>

                        <div class="d-flex align-items-end justify-content-center">
                            <span class="number">{{
                                    parseFloat(product.min_amount || 0).toPrettyFormat()
                                }} - {{ parseFloat(product.max_amount || 0).toPrettyFormat() }}</span>
                            <span class="unit fw-600 ms-2">{{ product.amount_label }}</span>
                        </div>

                    </div>
                    <div class="col-md-12 text-center">
                        <span>Okres: </span>
                        <div class="d-flex align-items-end justify-content-center">

                            <span class="number">{{ product.min_period || 0 }} - {{ product.max_period || 0  }}  </span>
                            <span class="unit  fw-600 ms-2">{{ product.period_label }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <v-control-toggle v-model="product.checked" :color="[null,'primary']"
                                  :label="['Zaznacz ofertę', 'Zaznaczono']" size="lg"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "v-wniosker-product",
    props: {
        product: {required: true}
    }
}
</script>