import {watch} from "vue";

export default {

    install(app, {url}) {
        watch( app.config.globalProperties.$auth.appKey, appKey => {


            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `${url}${appKey}.js`;
            script.async = false;

            document.head.appendChild(script)

        });
    }

}