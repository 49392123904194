<template>
    <div class="d-flex">
        <aside :class="{collapsed}" class="sidebar d-flex flex-column">
            <div class="logo py-2">
                <img v-if="collapsed" alt="Loando Direct" src="../assets/loando-logo-min.svg"/>
                <img v-else alt="Loando Direct" src="../assets/loando-logo.svg"/>
            </div>

            <div class="mt-4 d-grid gap-2">

                <v-button :route="{name:'agent.apply'}" color="gold" icon="file-add" label="Złóż nowy wniosek"
                          size="lg"/>
            </div>


            <nav class="pt-3 flex-grow-1">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <v-button :btn="false" :route="{name:'agent.index'}" class="nav-link" icon="home"
                                  label="Kokpit"/>
                    </li>
                    <li class="nav-item">
                        <v-button :btn="false" :route="{name:'agent.clients'}" class="nav-link" icon="database"
                                  label="Baza klientów"/>
                    </li>
                    <li class="nav-item">
                        <v-button :btn="false" :route="{name:'agent.leads'}" class="nav-link" icon="file-list"
                                  label="Lista wniosków"/>
                    </li>
                    <li class="nav-item">
                        <v-button :btn="false" :route="{name:'agent.posts'}" class="nav-link" icon="newspaper"
                                  label="Aktualności"/>
                    </li>
                    <li class="nav-item">
                        <v-button :btn="false" :route="{name:'agent.offers'}" class="nav-link" icon="book"
                                  label="Baza wiedzy"/>
                    </li>
<!--                    <li class="nav-item">-->
<!--                        <v-button :btn="false" :route="{name:'agent.index'}" class="nav-link" icon="money"-->
<!--                                  label="Rozliczenia"/>-->
<!--                    </li>-->
                </ul>
            </nav>


            <div class="d-grid gap-2 ">

                <button :class="{'text-start': !collapsed, 'text-center': collapsed}"
                        class="btn btn-link  text-white p-0 opacity-75"
                        type="button"
                        @click="collapsed = !collapsed">

                    <i v-if="!collapsed" class="icon-menu-fold text-large pe-2"></i>
                    <i v-else class="icon-menu text-large"></i>
                    <span>Zwiń menu</span>

                </button>
            </div>

        </aside>
        <main class="d-flex flex-column">
          <v-model.filters-agent v-slot="{ form, submit, clear, agentsOptions, selectedAgent}">


            <div class="navbar px-6">

                <nav aria-label="breadcrumb" class="flex-grow-1">
                    <ol class="breadcrumb">
                        <template v-for="(position,index) in breadcrumbs" :key="index">
                            <li :class="{active: position.active }" class="breadcrumb-item">
                                <v-button :label="position.text" :route="position.to"></v-button>
                            </li>
                        </template>
                    </ol>
                </nav>

                <div class="position-relative ms-3 h-100" v-if="$auth.permissions.viewAgents">
                    <div class="d-flex me-3 h-100">
                      <button type="button" :class="{'btn-danger': selectedAgent, 'btn-outline-primary': !selectedAgent}" class="btn px-2" @click="showMenu( 'toggle' )">
                        <i class="icon-filter"/>
                      </button>
                      <div v-if="selectedAgent" class="border border-danger p-2 h-100" @click="showMenu( 'toggle' )">
                        <small class="d-block lh-small text-danger">Przeglądasz jako</small>
                        <strong class="text-dark fw-600 me-3 lh-small">{{ selectedAgent?.text }}</strong>

                        <small class="text-danger lh-small">Zmień</small>
                      </div>
                    </div>
                    <div :class="{show: menu === 'toggle'}" class="dropdown-menu auth-menu p-3 w-300">
                        <v-control-select label="Wybierz eksperta"   :item="{label:'text', value:'value'}" :options="agentsOptions" v-model="form.agent_id" />
                        <div class="d-flex">
                            <v-button color="primary" size="xs" label="Zmień" class=" flex-grow-1 mt-2 text-center" icon="file-add" @click="submit(); showMenu( 'toggle' )"/>
                        </div>
                      <div class="d-flex">
                            <v-button color="danger" :outline="true" size="xs" label="Wyłącz filtrowanie" class=" flex-grow-1 mt-2 text-center" icon="close-fill" @click="clear(); showMenu( 'toggle' )"/>
                        </div>

                    </div>
                </div>

                <div class="h-100 d-flex align-items-center  bg-light-white rounded py-1 px-2">
                    <div class="me-3 text-end ">
                        <p class="mb-0  fw-500 fs-small"> Potrzebujesz wsparcia? </p>
                        <p class="mb-0  text-dark fw-600 contact-nr">731 946 999</p>
                    </div>


                    <a class="fs-3  me-3 d-block lh-small" href="skype:wsparcie@loandodirect.pl?call" target="_top"><i
                        class="icon-skype"></i> </a>

                    <a class="fs-3 d-block lh-small" href="mailto:wsparcie@loandodirect.pl" target="_top"><i
                        class="icon-mail"></i> </a>


                </div>


                <div class="position-relative ms-3" v-if="$auth.user">
                    <button class="btn username text-dark p-0 btn-transparent" type="button" @click="showMenu('auth')">
                        <img :src="$auth.user.avatar" :alt="$auth.user.username"
                             class="rounded avatar"/>
                        <span>{{ $auth.user.username }}</span>
                        <i class="icon-arrow-down-s"/>
                    </button>
                    <ul :class="{show: menu === 'auth'}" class="dropdown-menu auth-menu" @click="menu = null">

                        <li v-if="$auth.permissions.hasDocuments">
                            <v-button :route="{name:'agent.documents'}" class="dropdown-item" label="Dokumenty"/>
                        </li>
                        <li>
                            <v-button :route="{name:'agent.settings'}" class="dropdown-item" label="Twoje ustawienia"/>
                        </li>
                        <li>
                            <v-button :route="{name:'agent.settings'}" class="dropdown-item" label="Zmień hasło"/>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <v-button :route="{name:'logout'}" class="dropdown-item" label="Wyloguj"/>
                        </li>
                    </ul>

                </div>


            </div>
            <div class="flex-grow-1 position-relative">
                <div class="container-fluid position-absolute top-0 p-6 h-100 overflow-auto">
                    <router-view/>
                </div>
                <div v-for="layer in layers" :key="layer" class="container-fluid position-absolute top-0 h-100  p-6 overflow-auto bg-back z-1">
                    <v-view :layer="layer" name="layer"/>
                </div>
                <div class="dashboard-backdrop" v-if="menu" @click="menu = null"/>
            </div>
          </v-model.filters-agent>
        </main>
    </div>
</template>

<script>
import VButton from "@/components/Button";
import agents from "@/models/options/agents";

export default {
    name: "route-agent",

    components: {VButton},
    view: 'default',
    route: {
        title: 'Partner',
        path: '/agent',
        redirect: {
            name: 'agent.index'
        },
        before(from, to, next) {
            this.$auth.check()
                .then(() => {
                    next();
                })
                .catch(() => {
                    next({name: 'login'});
                })
        }
    },
    data() {
        return {
            test: null,
            menu: false,
            collapsed: false,
            selectedGlobalAgent: null
        }
    },
    methods: {
        showMenu(menu) {
            this.menu = this.menu === menu ? null : menu;
        },
        changeGlobalAgent() {

        }
    },

    computed: {
        globalAgent: {
            get() {
                return this.$route.query.agent ?? '';
            },
            set(id) {

            }
        },
        layers() {
            return this.$route.matched.map((route, i) => {
                return route.components.layer ? i : null;
            }).filter(x => !!x);
        },
        breadcrumbs() {
            return this.$route.matched.map((route, i) => {

                return {
                    text: route.meta.title || route.meta.name,
                    active: i === this.$route.matched.length - 1,
                    to: {name: route.name || route.meta.name, params: route.params}
                }
            });
        }
    }
}
</script>
