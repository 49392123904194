<template>
    <section>
        <v-model.leads v-slot="{model, query, fetch, clearQuery, pagination, search}">

            <div class="d-flex justify-content-between">
                <div class="hgroup">
                    <h1>Lista wniosków</h1>
                    <h2>To Twoje centrum wniosków</h2>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <!--                    <v-model.filters-leads v-slot="{ model : query }">-->

                    <span class="card-title mb--4">Filtruj wyniki</span>


                    <div class="d-flex   align-items-end">
                        <div class="flex-grow-1  d-grid gap-2">
                            <v-button :label="advanced ? 'Ukryj zaawansowane' : 'Szukanie zaawansowane'" :outline="true"
                                      color="secondary"
                                      icon="arrow-down-s"
                                      size="xs" @click="advanced = !advanced"/>
                        </div>
                        <div class="flex-grow-5 d-flex">
                            <div class="d-flex align-items-center mx-1">
                                <template v-if="query.from_at !== 'custom'">
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'today']" class="btn-xs mx-1"
                                                      label="Dziś"/>
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'yesterday']" class="btn-xs mx-1"
                                                      label="Wczoraj"/>
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'3days']" class="btn-xs mx-1"
                                                      label="3 dni"/>
                                    <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                      :options="[null,'7days']" class="btn-xs mx-1"
                                                      label="7 dni"/>
                                </template>

                                <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                                                  :options="[null,'custom']" class="btn-xs mx-1"
                                                  label="Własny"/>

                                <v-control-date-range v-if="query.from_at === 'custom'"
                                                      v-model="query.created_at"></v-control-date-range>
                            </div>
                            <div class="d-flex align-items-center mx-1">
                                <v-control-toggle v-if="$auth.permissions.mobileEnabled" v-model="query.withMobileApp"  :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="smartphone"
                                                  title="Klient ukończył wypełanie formularza"/>

                                <v-control-toggle v-model="query.leadFormFinished" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="file-paper"
                                                  title="Klient ukończył wypełanie formularza"/>
                                <v-control-toggle v-model="query.leadAgreementsSent" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="send"
                                                  title="SMS ze zgodami został wysłany"/>
                                <v-control-toggle v-model="query.leadAgreementsChecked" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="checkbox-multiple"
                                                  title="Klient zaznaczył zgody"/>
                                <v-control-toggle v-model="query.leadProcessingStarted" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="search"
                                                  title="Proces wnioskowania rozpoczęty"/>
                                <v-control-toggle v-model="query.leadAccepted" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="thumb-up"
                                                  title="Wniosek został zaakceptowany"/>
                                <v-control-toggle v-model="query.leadRedirected" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="direction"
                                                  title="Klient wybrał ofertę"/>
                                <v-control-toggle v-model="query.leadPaid" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="money-dollar" title="Wniosek został wypłacony"/>
                                <v-control-toggle v-model="query.actionDontCall" :color="['gray','red','green']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="phone-off"
                                                  title="Nie dzwonić do klienta"/>
                                <v-control-toggle v-model="query.actionNotAnsweredThePhone"
                                                  :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="phone-x"
                                                  title="Klient nie odbiera telefonu"/>
                                <v-control-toggle v-model="query.actionPleaseCallBack" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="arrow-go-forward"
                                                  title="Oddzwonić do klienta"/>
                                <v-control-toggle v-model="query.actionNote" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="phone-incoming" title="Zawiera notatkę"/>
                                <v-control-toggle v-model="query.actionDecisionUndertaking"
                                                  :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="questionnaire"
                                                  title="Klient nie podjął jeszcze decyzji"/>
                                <v-control-toggle v-model="query.actionVoiceMail" :color="['gray','green','red']"
                                                  :options="[null,'include','exclude']" class="btn-transparent p-1"
                                                  icon="record-mail"
                                                  title="Poczta głosowa"/>
                            </div>
                        </div>
                        <div class="flex-grow-1  d-grid gap-2">
                            <v-button :outline="true" color="secondary" icon="file-add-line-1" label="Wyczyść filtry"
                                      size="xs" @click="clearQuery"/>
                            <v-button color="primary" icon="search" label="Szukaj" size="sm" submit @click="search"/>
                        </div>
                    </div>
                    <div v-if="advanced" class="mt-3 pt-3 border-top">
                        <div class="row">
                            <div class="col-md">
                                <v-control-input v-model="query['personId'].equal" label="Pesel"
                                                 placeholder="Podaj nr pesel"/>
                            </div>
                            <div class="col-md">
                                <v-control-input v-model="query['phone'].equal" label="Telefon"
                                                 placeholder="Podaj nr tel."/>
                            </div>
                            <div class="col-md">
                                <v-control-input v-model="query['email'].equal" label="Email"
                                                 placeholder="Podaj email"/>
                            </div>
                            <div class="col-md">
                                <v-control-input v-model="query['firstName'].equal" label="Imię"
                                                 placeholder="Podaj imię"/>
                            </div>
                            <div class="col-md">
                                <v-control-input v-model="query['lastName'].equal" label="Nazwisko"
                                                 placeholder="Podaj nazwisko"/>
                            </div>

                        </div>

                    </div>
                    <!--                    </v-model.filters-leads>-->
                </div>

            </div>

            <div class="card mt-3">
                <div class="card-body ">
                    <span class="card-title">Wyniki wyszukiwania ({{ pagination?.total }})</span>

                    <v-table v-slot="{item}" :items="model.leads" class="clients">
                        <v-column label="Klient">
                            <div class="d-flex align-items-center">
                                <router-link :to="{name:'agent.leads.preview', params: {lead: item.uid}}">
                                    <div class="fs-4">
                                        <v-icon icon="arrow-right-s"></v-icon>
                                    </div>
                                </router-link>
                                <div>
                                    <span class="d-block name">{{ item.firstName || '-' }} {{ item.lastName || '-' }}</span>
                                    <span class="d-block sub">{{ item.personId }}</span>
                                    <span class="d-block sub">{{ item.email }}</span>
                                    <span class="d-block sub">{{ item.phone }}</span>
                                </div>
                            </div>
                        </v-column>
                        <v-column label="Status wniosku" style="width: 180px">
                            <div class="d-flex justify-content-between">
                                <v-collection v-slot="{item}" :items="item.statuses.event_statuses">
                                    <v-icon :class="{['text-'+item.color]: !!item.color}" :icon="item.icon"
                                            :title="item.hint"
                                            class="fs-4"/>
                                </v-collection>
                            </div>
                            <div :class="{['bg-'+item.statuses.lead_status.color]: true}" class="lead-status">
                                {{ item.statuses.lead_status.hint }}
                            </div>
                            <div v-if="item.withMobileApp" class="text-center fw-700 p-1 text-uppercase text-xs vert-middle"> <span class="text-lg"> &bull;</span>	Aplikacja</div>
                        </v-column>
                        <v-column class="text-end" label="Oferty">
                            <span class="d-block number">{{ item.applications_count }}</span>
                            <span class="d-block unit">szt.</span>
                        </v-column>
                        <v-column class="text-end" label="Wypłacono">
                            <span class="d-block number">{{ item.applications_paid_count ?? '0' }}</span>
                            <span class="d-block unit">szt.</span>
                        </v-column>
                        <v-column class="text-end" label="Kwota">
                            <span class="number">{{ item.amount }}</span>
                            <span class="d-block unit">PLN</span>
                        </v-column>
                        <v-column class="text-end" label="Okres">
                            <span class="number">{{ item.period }}</span>
                            <span class="d-block unit">msc</span>
                        </v-column>
                        <v-column class="text-center" label="Data wniosku">
                            <div class="d-inline-block text-start">
                                <v-date :date="item.created_at" class="d-block" format="YYYY/MM/DD"/>
                                <v-date :date="item.created_at" class="d-block" format="HH:mm"/>
                            </div>

                        </v-column>
                        <v-column class="text-center" label="Status rozmowy">
                            <div class="row" style="width: 90px; margin: 0 auto">
                                <v-collection v-slot="{item}" :items="item.statuses.action_statuses">
                                    <v-icon :class="{['text-'+item.color]: !!item.color}" :icon="item.icon"
                                            :title="item.hint"
                                            class="fs-4 col-md-4"/>
                                </v-collection>
                            </div>
                        </v-column>
                        <v-column v-if="$auth.permissions.viewAgents" label="Agent/Ekspert">
                            <v-agent :agent="item.agent"/>
                        </v-column>
                        <v-column class="actions">
                            <v-button v-if="item.continueLeadBtn" :href="item.redirect_url" color="gold" icon="feedback"
                                      label="Uzupełnij wniosek"/>
                            <v-button v-else
                                      :route="{name:'agent.leads.preview', params: {lead: item.uid}}" color="primary"
                                      icon="file-add-line-1" label="Szczegóły wniosku"/>

                        </v-column>
                    </v-table>

                    <v-pagination :pagination="pagination"/>
                </div>
            </div>
        </v-model.leads>
    </section>
</template>

<script>
import VControlToggle from "@/components/Control/Toggle";
import agents from "@/models/options/agents";
import VColumn from "@/components/Column";

export default {
    name: "route-agents.leads",
    components: {VColumn, VControlToggle},
    mixins: [agents],
    route: {
        path: 'wnioski',
        title: 'Lista wniosków'
    },
    data() {
        return {
            advanced: false
        }
    }
}
</script>
