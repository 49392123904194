<template>
    <section>
        <v-model.offers v-slot="{model}">
            <div class="d-flex justify-content-between">

                <div class="hgroup">
                    <h1>Baza wiedzy</h1>
                    <h2>To Twoje centrum dowodzenia - katalog produktów</h2>
                </div>

            </div>
            <div class="row">
                <div class="col-md-3" v-for="offer in model.offers" :key="offer.id">
                    <div class="card">
                        <div class="card-body text-center d-flex flex-column align-items-center">
                            <span class="card-title">{{ offer.title }}</span>
                               <img :src="offer.image" :alt="offer.title" class="d-block logo-image my-3"/>
                            <v-button class="btn btn-transparent text-secondary" target="_blank" :href="offer.card" label="Pobierz katalog" icon="file-pdf-line"/>
                        </div>
                    </div>
                </div>
            </div>
        </v-model.offers>

    </section>
</template>

<script>
export default {
    name: "route-agent.offers",
    route: {
        path: '/baza-wiedzy',
        title: 'Baza wiedzy'
    }
}
</script>
