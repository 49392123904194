<template>
    <label class="btn" :class="classes" @click="next">
        <v-icon v-if="icon" :icon="currentIcon" />
        <span v-if="label">{{ currentLabel }}</span>
        <input type="hidden" v-model="value"/>
    </label>
</template>

<script>
import control from "@/mixins/control";

export default {
    name: "v-control-toggle",
    mixins: [control],
    props: {
        label: {},
        icon: {},
        color: {},
        size: {},
        options: { default: [false,true]},
        outline: {default: false}
    },
    methods: {
        next() {
            this.value = this.options[this.index < this.options.length+1 ? this.index+1 : 0]
        }
    },
    computed: {
        btnColorClass() {
            return ["btn", this.outline ? "outline" : null, this.currentColor]
                .filter((x) => !!x)
                .join("-");
        },
        classes() {
            return {
                [this.btnColorClass]: !!this.color,
                ['btn-'+this.size]: !!this.size
            };
        },
        currentIcon() {
            return Array.isArray(this.icon) ? this.icon[this.index] : this.icon;
        },
        currentColor() {
            return Array.isArray(this.color) ? this.color[this.index] : this.color;
        },
        currentLabel() {
            return Array.isArray(this.label) ? this.label[this.index] : this.label;
        },
        index() {

            const index = this.options.indexOf(this.value)

            return index > 0 ? index : 0;
        }
    }
}
</script>

<style scoped>

</style>