<template>
    <div class="pagination mb-0">
        <ul class="pagination">
            <li class="page-item" v-for="(page, index) in pages" :key="index" :class="{ active: page === currentPage }">
                <button type="button" v-if="page" class="page-link" @click.prevent="toPage(page)">{{ page }}</button>
                <span class="page-link" v-else>...</span>
            </li>
        </ul>
    </div>
</template>

<script>

import component from "@/mixins/component";

export default {
    name: "VPagination",
    mixins: [component],
    props: {
        pagination: {}
    },
    methods: {
        toPage(page) {
            // this.model.bindQuery({ page });
            // this.model.component.fetchData();
            this.model.toPage(page);
        }
    },
    computed:  {

        currentPage() {
            return this.pagination?.current_page;
        },
        lastPage() {
            return this.pagination?.last_page;
        },
        pages() {
            if (!this.pagination) return [];

            const c = Math.floor(5 / 2);
            const c_pages = [...Array(c).keys()].map((p) => p + 1);

            const start = Math.max(0, this.currentPage - c);
            const last = Math.min(this.currentPage + c, this.lastPage);
            const end = Math.max(this.lastPage - c, 0);

            const beginning_pages =
                this.currentPage - c > 1 ? [...c_pages.map((p) => p).filter((p) => p < this.currentPage - c), 0] : [];
            const end_pages =
                this.currentPage < this.lastPage - c
                    ? [0, ...c_pages.map((p) => p + end).filter((p) => p > this.currentPage + c)]
                    : [];
            const current_pages = [...Array(this.lastPage).keys()]
                .map((p) => p + 1)
                .filter((p) => p >= start && p <= last);

            const pages = [...beginning_pages, ...current_pages, ...end_pages];
            return pages.length > 1 ? pages : [];
        },
    }
}
</script>
