<template>
<div>
    <router-view  v-if="!$auth.isLoading()"/>
    <div v-else>
        <div class="fill d-flex align-items-center justify-content-center max-height d-block" >
            <div class="text-center py-3">
                <div class="logo py-5 text-center">

                    <img alt="Loando Direct" src="./assets/loando-logo-dark.svg"/>
                </div>
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div>
                    Trwa uwierzytelnianie
                </div>
            </div>
        </div>
    </div>
</div>

</template>