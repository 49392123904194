<template>
    <component :is="tag" v-bind="attr" :class="classes">
        <div v-if="loading" class="spinner-border spinner-border-sm me-2"   role="status">
            <span class="visually-hidden"></span>
        </div>
        <v-icon v-else-if="icon" :icon="icon" class="pe-1" />
        <span>{{ label }}</span>
    </component>
</template>

<script>
import VLoading from "@/components/Loading";
export default {
    name: "v-button",
    components: {VLoading},
    props: {
        btn: { type: Boolean, default: true },
        color: { type: String },
        class: { type: String },
        icon: { type: String },
        iconActive: { type: String },
        size: { type: String },
        block: { type: Boolean },
        outline: { type: Boolean },
        label: { type: String },
        href: { type: String },
        route: { type: Object },
        bold: { type: Boolean },
        target: { type: String },
        submit: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    computed: {
        btnSizeClass() {
            return ["btn", this.size].join("-");
        },
        btnColorClass() {
            return ["btn", this.outline ? "outline" : null, this.color]
                .filter((x) => !!x)
                .join("-");
        },
        classes() {
            return {
                btn: this.btn,
                [this.btnColorClass]: !!this.color,
                "btn-block": this.block,
                [this.btnSizeClass]: this.size,
                [this.class]: !!this.class,
                "text-bold": this.bold,
            };
        },
        tag() {

            if(this.route)
                return 'router-link';

            if(this.href)
                return 'a';

            return 'button';
        },
        attr() {

            if(this.route)
                return {to: this.route};

            if(this.href)
                return {href: this.href};

            return {
                type: this.submit ? 'submit' : 'button'
            };
        }
    }
}
</script>

<style scoped>

</style>