Number.prototype.toPrettyFormat = function () {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}




Array.prototype.filterRecursive = function(predicate, childProperty){
    let filterResults = [];
    let filterAndPushResults = (arrayToFilter)=>{
        let elementsWithChildren  = arrayToFilter.filter(x=>x !== undefined).filter(x=>x[childProperty]);
        let filtered = arrayToFilter.filter(predicate);
        filterResults.push(...filtered);
        if(elementsWithChildren.length){
            let childElements = [];
            elementsWithChildren.forEach(x=>{
                childElements.push(...x[childProperty]);
            });
            filterAndPushResults(childElements);
        }
    };
    filterAndPushResults(this);
    return filterResults;
}

Array.prototype.intersect = function (b) {
    return this.filter(value => b.includes(value));
}

Array.prototype.diff = function (b) {
    return this.filter(value => !b.includes(value));
}

Array.prototype.unique = function () {
    return Object.keys(Object.fromEntries(this.map(x => [x])))
}

Object.clearObject = (obj) => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([, b]) => !!b)
            .map(([a, b]) => {

                if(b instanceof Object && !Array.isArray(b))
                    b = Object.clearObject(b);

                return [a,b];
            })
    );
}
Object.isEqual = function(o1, o2){

    for (let p in {...o1, ...o2}) {

        if(!o1.hasOwnProperty(p)) return false;
        if(!o2.hasOwnProperty(p)) return false;

        if(o1[p] instanceof Object && !Array.isArray(o1[p]) && o2[p] instanceof Object && !Array.isArray(o2[p]))
            if(Object.isEqual(o1[p], o2[p])) continue; else return false;

        if(o1[p] !== o2[p])
            return false;

    }

    return true;
};
Date.prototype.subMonths = function (months) {

    this.setMonth(this.getMonth() - months);

    return this;
}