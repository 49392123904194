<template>
    <div class="wniosker-offers">
        <span class="d-block fw-600 mb-2" v-if="offer.label && offer.elements">{{ offer.label }}</span>
        <div class="row">
            <v-collection v-if="hasOffers" :items="offer.elements">
                <template v-slot:default="{item}">
                    <v-wniosker-offers v-if="item.mode === 'group' && item.elements?.length" :offer="item" :root="false"/>
                    <v-wniosker-offer  v-if="item.mode !== 'group'" :offer="item"/>
                </template>
               <template v-slot:loading>
                   <div class="py-3">
                       <v-loading color="secondary" text="Trwa wczytywanie..."/>
                   </div>
               </template>

            </v-collection>
            <div v-else class="d-flex">
                <div class="col-md-3">
                    <div class="product mb-3">
                        <div class="p-3">
                            <div class="py-1 px-5 mx-3">
                                <img  src="../../assets/loando-logo-dark.svg" class="logo-image"/>
                            </div>
                            <div class="text-primary fs-5 fw-600 py-3">
                                Szukamy oferty dla <br/> Twojego klienta
                            </div>
                        </div>
                        <div class="d-grid gap-2">
                            <v-button color="gold"  @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh" />
                        </div>
                    </div>
                </div> <div class="col-md-3">
                <div class="product blur  mb-3">
                    <div class="p-3">
                        <div class="py-1 px-5 mx-3">
                            <img  src="../../assets/loando-logo-dark.svg" class="logo-image"/>
                        </div>
                        <div class="text-primary fs-5 fw-600 py-3">
                            Szukamy oferty dla <br/> Twojego klienta
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <v-button color="light"  @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh" />
                    </div>
                </div>
            </div> <div class="col-md-3">
                <div class="product blur  mb-3">
                    <div class="p-3">
                        <div class="py-1 px-5 mx-3">
                            <img  src="../../assets/loando-logo-dark.svg" class="logo-image"/>
                        </div>
                        <div class="text-primary fs-5 fw-600 py-3">
                            Szukamy oferty dla <br/> Twojego klienta
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <v-button color="light"  @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh" />
                    </div>
                </div>
            </div> <div class="col-md-3">
                <div class="product blur  mb-3">
                    <div class="p-3">
                        <div class="py-1 px-5 mx-3">
                            <img  src="../../assets/loando-logo-dark.svg" class="logo-image"/>
                        </div>
                        <div class="text-primary fs-5 fw-600 py-3">
                            Szukamy oferty dla <br/> Twojego klienta
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh" />
                    </div>
                </div>
            </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "v-wniosker-offers",
    emits: ['refresh'],
    props: {
        offer: {},
        root: { type: Boolean, default: true}
    },
    computed: {
        hasOffers() {
            return this.root ? this.offer.elements?.filterRecursive( (item) => item.mode !== 'group', 'elements')?.length : true;
        }
    }
}
</script>

<style scoped>

</style>